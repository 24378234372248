<template>
 <div class="grid grid-cols-1 sm:grid-cols-2 mt-8">
  <div class="col-span-1">
   <template>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 md:mb-10">
     <div class="col-span-1">
      <router-link :to="{ name: 'marketplace' }" class="inline-flex mt-2"
       ><ion-icon
        name="arrow-back-outline"
        class="text-deal text-xl mr-2"
       ></ion-icon>
       <h1 class="text-13 text-gray-700 font-bold mt-1">
        All Vendors
       </h1>
      </router-link>
     </div>
     <div class="col-span-1">
      <div class="relative">
       <input
        type="search"
        name="search"
        class="border border-solid border-blue-200 rounded placeholder-gray-500 outline-none pl-10 pr-2 py-3 text-sm w-full"
        placeholder="Search for vendors"
        v-model="searchQuery"
        @keyup.prevent="getAllMerchants"
       />
       <ion-icon
        name="search-outline"
        class="text-gray-500 absolute z-10 text-lg ml-3 left-0"
        style="top: 50%; transform: translateY(-50%);"
       ></ion-icon>
      </div>
     </div>
    </div>
    <div class="flex justify-start mt-10" v-if="merchants.loading">
     <sm-loader></sm-loader>
    </div>
    <grid
     :items="merchants.data"
     @selected="selectVendor($event)"
     v-else
    ></grid>

    <branch
     :vendor="selectedVendor"
     v-if="selectedVendor"
     :key="componentKey"
     @success="success"
     ref="branch"
    ></branch>

    <order-success
     ref="successModal"
     message="Order Placed Successfully"
     additional-text="Click on order history to confirm your order upon delivery/pick up"
     link="order-history"
    >
     Go To Order History
    </order-success>
   </template>
  </div>
 </div>
</template>
<script>
 export default {
  components: {
   Grid: () => import("./components/Grid"),
   Branch: () => import("./components/branch/Index"),
   OrderSuccess: () => import("./components/OrderSuccess")
  },
  data() {
   return {
    merchants: this.$options.resource([]),
    searchQuery: "",
    componentKey: 0,
    selectedVendor: null
   };
  },
  beforeMount() {
   this.getAllMerchants();
  },
  watch: {
   "selectedVendor.business_name"() {
    this.componentKey += 1;
   }
  },
  methods: {
   async getAllMerchants() {
    this.merchants.setLoading();
    await this.sendRequest(
     "personal.marketplace.merchants",
     60,
     this.searchQuery,
     [],
     {
      success: (response) =>
       (this.merchants.data = [
        ...this.merchants.data,
        ...response.data.datatable.data
       ]),
      error: (error) => console.log(error)
     }
    );
    this.merchants.loading = false;
   },
   success() {
    this.$refs.successModal.openSuccessModal();
    this.$refs.branch.close();
   },
   selectVendor(vendor) {
    this.selectedVendor = { ...vendor };
    setTimeout(() => this.$refs.branch.open(), 500);
   }
  },
  mounted() {
   this.$el.addEventListener("scroll", () => {
    const scrollTop =
     document.documentElement.scrollTop || document.body.scrollTop;

    if (scrollTop + window.innerHeight >= document.body.clientHeight) {
     console.log(scrollTop);
    }
   });
  }
 };
</script>
